import React from "react";
const BiographySection = () => {
  return (
    <>
      <div id="biography-section"></div>
      <div className="section-title-b">Biography</div>
      <section className="biography-container">
        <div className="biography-card">
        <div className="biography-text biography">
            <p>
                Thomas van Rossum is a Dutch composer and sound designer living
                in the Netherlands. He studied at the University of the Arts
                Utrecht (2016 - 2020), where he worked excessively with game
                developers to develop his skillset.
              </p>
            </div>
          <div className="biography">
            <div className="biography-text">
              <p>
                Working mainly on Virtual Reality video games, Thomas is
                primarily focused on creating immersive 360 degrees soundscapes
                which allow players to feel sonically surrounded, permitting
                them to fully submerge in the virtual space. In his music he
                combines virtual orchestration with synthesizers and creative
                sound design, along with compelling harmonic gestures, to create
                unique and effective scores.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BiographySection;
